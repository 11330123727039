import patternImage from '@/assets/img/join/join_complete_pattern@2x.png';
import Button from '@/components/Button';
import Row from '@/components/Grid/Row';
import { Tit } from '@/components/Titles';
import BR from '@/components/Typing/BR';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { FormContainer } from '@/page-blocks/auth/AuthCommon';
import { navigate, PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

const ContentBox = styled.div`
  margin: 0 auto;
  max-width: 544px;
  ${Tit} {
    line-height: 1.5;
    letter-spacing: -2px;
  }
  img {
    width: 100%;
    object-fit: contain;
    margin-top: 96px;
    margin-bottom: 71px;
  }
  p {
    line-height: 1.6;
    letter-spacing: -1.2px;
  }
  ${breakpoint(`mobile`)} {
    ${Tit} {
      line-height: 1.58;
      letter-spacing: -1.2px;
    }
    img {
      margin-top: 72px;
      margin-bottom: 56px;
    }
  }
`;
const ContentBox1 = styled(ContentBox)`
  padding-top: 96px;
  padding-bottom: 96px;
  ${breakpoint(`mobile`)} {
    padding-bottom: 72px;
  }
`;
const ButtonRow = styled(Row)`
  margin: 0 -16px;
  .col {
    width: 50%;
    padding: 0 16px;
  }
  ${breakpoint(`mobile`)} {
    margin: 0 -12px;

    .col {
      padding: 0 12px;
    }

    ${Button} {
      font-size: 16px;
      white-space: nowrap;
      // padding: 0;
    }
  }
`;

const JoinCompletePage: FC<PageProps> = ({ location }) => (
  <LayoutWithTitle
    location={location}
    title="회원가입 완료"
    description="회원가입이 정상적으로 완료 되었습니다"
  >
    <FormContainer>
      <ContentBox1>
        <h2>
          <Tit size="s1-5" color="sky" weight="normal">
            유니세프한국위원회 회원이
            <br />
            되신 것을 환영합니다
          </Tit>
        </h2>
        <img src={patternImage} alt="패턴이미지" />
        <p>
          <Tit size="s4">
            가입하신 아이디로 유니세프한국위원회 <BR only="mobile" /> 웹사이트의
            {` `}
            <BR only="desktop" />
            다양한 서비스를 <BR only="mobile" />
            이용할 수 있습니다.
            <br />
            <br />
            감사합니다.
          </Tit>
        </p>
      </ContentBox1>
      <ButtonRow>
        <div className="col">
          <Button outline onClick={() => navigate(`/`)} full>
            홈으로 이동
          </Button>
        </div>
        <div className="col">
          <Button onClick={() => navigate(`/auth/login`)} full>
            로그인하러 가기
          </Button>
        </div>
      </ButtonRow>
    </FormContainer>
  </LayoutWithTitle>
);

export default JoinCompletePage;
